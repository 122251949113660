import './modules/script';
import './modules/map/mapalex';
// import './modules/split';

import AOS from 'aos';
// ..
AOS.init();
AOS.init({
  // Global settings:
  disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
  initClassName: 'aos-init', // class applied after initialization
  animatedClassName: 'aos-animate', // class applied on animation
  useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
  disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)


  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  offset: 120, // offset (in px) from the original trigger point
  delay: 0, // values from 0 to 3000, with step 50ms
  duration: 400, // values from 0 to 3000, with step 50ms
  easing: 'ease', // default easing for AOS animations
  once: false, // whether animation should happen only once - while scrolling down
  mirror: false, // whether elements should animate out while scrolling past them
  anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

});
// AOS.init({
//   disable: function() {
//     var maxWidth = 767;
//     return window.innerWidth < maxWidth;
//   }
// });




//GSAP h2 animation
// const sections = gsap.utils.toArray('.section');

// sections.forEach((section, index) => {
// 	const heading = section.querySelector('h2');

// 	let split = SplitText.create(heading, { type: "words,chars" });

// 	//create an animation for each heading
// 	let animation = gsap.from(split.chars, {
//     opacity: 0,
//     stagger: {
//     amount: 1,
//     from: "random"
//   },ease: "linear",
// });


// 		ScrollTrigger.create({
// 		trigger: section,
// 		start: "top 40%",
// 		toggleActions: "play none none reverse",
// 		animation: animation,
// 		markers: false
// 	});



// });

// ScrollTrigger.create({
// trigger: "#featured",
// start: "top 40%",
// markers: true
// });

gsap.config({trialWarn:false});

// MorphSVGPlugin.convertToPath("rect");
// gsap.to("#start", {
//   duration: 2,
//   morphSVG: {
//     shape: "#end",
//      type: "rotational",
//      map: "position",
//   },
//   fill:"#000000"
// });
gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

let smoother = ScrollSmoother.create({
  wrapper: '#smooth-wrapper',
  content: '#smooth-content',
  smooth:2,
  smoothTouch:0.1,
  effects:true,
});
