/*Header */
// jQuery(document).ready(function($) {
//   // Cache the image element and the current image source
//   var $changeImage = $('#changeimage');
//   var currentImageSrc = '';
//
//   // Handle hover on menu items
//   $('.headermenu li').hover(function() {
//     var dataImage = $(this).data('image');
//     if (dataImage !== currentImageSrc) {
//       $changeImage.attr('src', dataImage);
//       currentImageSrc = dataImage;
//     }
//   }, function() {
//     // Clear the image source when the mouse leaves the menu item
//     if (currentImageSrc !== '') {
//       $changeImage.attr('src', currentImageSrc);
//     }
//   });
//
//   // Initialize variables for animation
//   var mouseX = 0;
//   var mouseY = 0;
//   var imageX = 0;
//   var imageY = 0;
//   var imageWidth = $changeImage.width();
//   var imageHeight = $changeImage.height();
//   // Function to update mouse position
//   function updateMousePosition(e) {
//     mouseX = e.clientX;
//     mouseY = e.clientY;
//   }
//
//   // Function to smoothly move the image
//   function moveImage() {
//     var dx = (mouseX - imageX) * 0.05 - imageWidth/2;
//     var dy = (mouseY - imageY) * 0.05 - imageHeight/2;
//
//     imageX += dx;
//     imageY += dy;
//
//     $changeImage.css({
//       transform: `translate(${imageX}px, ${imageY}px)`,
//     });
//
//     requestAnimationFrame(moveImage);
//   }
//
//   // Attach mousemove event listener to update the mouse position
//   $(document).on('mousemove', updateMousePosition);
//
//   // Start the animation
//   requestAnimationFrame(moveImage);
// });

/**/

// jQuery(document).ready(function($) {
// $( "#menu-main-menu li").each(function( index ) {
//     var img_src=$(this).find('a').find('img').attr('src');
//     if(index==1){
//       $('#changeimage').attr('src',img_src);
//     }
// });
// });
// $( "#menu-main-menu li").each(function( index ) {
//       var img_src=$(this).find('a').find('img').attr('src');
//       $(this).attr('data-image',img_src);
// });

/*scroll header*/
$(window).scroll(function() {
  if ($(document).scrollTop() > 40) {
      $('.header_wrapper').addClass('scrolled');
  }
  else{
    $('.header_wrapper').removeClass('scrolled');
  }
});

/*Detect Direction*/
var lastScrollTop = 0;
$(window).scroll(function(event){
   var st = $(this).scrollTop();
   if (st > lastScrollTop){
       // downscroll code
       $('.header_wrapper').addClass('down');
   } else {
      // upscroll code
      $('.header_wrapper').removeClass('down');
   }
   lastScrollTop = st;
});


/*Language Switcher*/
$(".language.is-active").on('click', function() {
    return false;
})

/*Open Menu*/
$(".menu_button").on('click', function() {

      if($(this).hasClass('is-active')){
          //$('#changeimage').css('opacity','0');
          $("body").css('overflow-y','scroll');
          $(this).removeClass('is-active');
          $('.menu_button').removeClass('is-active');
          $('.header_menu_controls .menu_button').addClass('is-active');
          $('.headermenu').removeClass('is-active');
          $('.header_wrapper').removeClass('openmenu');
          //$('.menu li').removeClass('aos-animate');
        }
        else{
        //  $('#changeimage').css('opacity','1');
          $(this).addClass('is-active');
          $('.header_menu_controls .menu_button').addClass('is-active');
          jQuery("body").css('overflow-y','hidden');
          $('.headermenu').addClass('is-active');
          $('.header_wrapper').addClass('openmenu');
      }


});

if (window.innerWidth < 768) {
  document.querySelectorAll('#home-services .service').forEach((el) => {
    el.addEventListener('click', () => {
      el.classList.toggle('active');
    })
  })
}

import Swiper, { Navigation } from 'swiper';
//document.addEventListener("DOMContentLoaded", function() {
const servicesSwiper = new Swiper(".services-swiper", {
  slidesPerView: 1,
  breakpoints: {
    1024: {
      slidesPerView: 3,
    },
    768: {
      slidesPerView: 2,
    },
  },
  pagination: {
    el: ".services-swiper-pagination",
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '"></span>';
    },
  },
  navigation: {
    prevEl: '.services-slider-prev',
    nextEl: '.services-slider-next',
  },
});

const servicesContent = document.querySelectorAll('#services .service .service-right')

servicesContent.forEach((el) => {
  if (el.offsetHeight == 600) {
    el.classList.add('expandable-service');
    el.querySelector('.service-expand').addEventListener('click', () => {
      console.log(el);
      if (!el.classList.contains('active')){
        el.style.maxHeight = el.scrollHeight + 'px';
        el.classList.add('active');
      } else {
        el.style.maxHeight = '600px';
        el.classList.remove('active');
      }
    })
  }
})

const partners = document.querySelectorAll('.partner');
console.log(partners);
const partnerInfos = document.querySelectorAll('.partner-info');

partners.forEach((partner) => {
    partner.querySelector('.partner-button').addEventListener('click', () => {
        const partnerId = partner.dataset.partner;

        // Remove 'is-active' from all partnerInfos
        partnerInfos.forEach((info) => {
            info.classList.remove('is-active');
        });

        // Add 'is-active' to the correct partnerInfo
        const partnerInfo = document.querySelector(`.partner-info[data-info="${partnerId}"]`);
        if (partnerInfo) {
            partnerInfo.classList.add('is-active');
        }
        document.querySelector('.partner-backdrop').classList.add('is-active');
        $("body").css('overflow-y','hidden');
    });
});

document.addEventListener('DOMContentLoaded', function() {
  document.querySelectorAll('.partner-info-close').forEach((button) => {
      button.addEventListener('click', () => {
          const partnerInfo = document.querySelector('.partner-info.is-active');
          if (partnerInfo) {
              partnerInfo.classList.remove('is-active');
          }
          document.querySelector('.partner-backdrop').classList.remove('is-active');
          $("body").css('overflow-y','scroll');
      });
  });
});

const servicesSection = document.querySelector('#services');
if (servicesSection) {
  document.body.style.backgroundColor = '#F7CA17';
  document.addEventListener('scroll', function() {
    const scrollPosition = window.scrollY;
    if (scrollPosition > (servicesSection.offsetHeight / 2)) {
      document.body.style.backgroundColor = '#B7B4A3';
    } else {
      document.body.style.backgroundColor = '#F7CA17';
    }
  });
} 

const aboutSwiper = new Swiper(".about-swiper", {
  slidesPerView: 1,
  draggable: true,
  grabCursor: true,
  speed: 800,
  spaceBetween: 100,
  navigation: {
    prevEl: '.about-swiper-button-prev',
    nextEl: '.about-swiper-button-next',
  },
});

// check if current page has aboutSwiper
if (document.querySelector('.about-swiper') != null) {
  aboutSwiper.on('slideChange', function () {
    if (aboutSwiper.activeIndex + 1 < 10) {
      document.querySelector('.current-index-about').textContent = '0' + (aboutSwiper.activeIndex + 1);
    } else {
      document.querySelector('.current-index-about').textContent = aboutSwiper.activeIndex + 1;
    }
  });
  // Update swiper amount and title
  if (aboutSwiper.slides.length < 10) {
    document.querySelector('.swiper-amount-about').textContent = '0' + aboutSwiper.slides.length;
  } else {
    document.querySelector('.swiper-amount-about').textContent = aboutSwiper.slides.length;
  }
}