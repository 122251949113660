var mapStyle = [
    {
        "featureType": "all",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "administrative.land_parcel",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "poi.attraction",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#f7ca17"
            }
        ]
    },
    {
        "featureType": "poi.attraction",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "poi.business",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#f7ca17"
            },
            {
                "saturation": "-20"
            }
        ]
    },
    {
        "featureType": "poi.business",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.government",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#f7ca17"
            }
        ]
    },
    {
        "featureType": "poi.government",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.medical",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#f7ca17"
            }
        ]
    },
    {
        "featureType": "poi.medical",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#f7ca17"
            }
        ]
    },
    {
        "featureType": "poi.place_of_worship",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#f7ca17"
            }
        ]
    },
    {
        "featureType": "poi.place_of_worship",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.school",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#f7ca17"
            }
        ]
    },
    {
        "featureType": "poi.school",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.sports_complex",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#f7ca17"
            },
            {
                "saturation": "-20"
            }
        ]
    },
    {
        "featureType": "poi.sports_complex",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "saturation": "100"
            },
            {
                "lightness": "100"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#000000"
            },
            {
                "lightness": "-100"
            },
            {
                "saturation": "-6"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    }
]


/*id of map here*/
if ($("#map").length > 0) {
  google.maps.event.addDomListener(window, 'load', initialize_map);

}

function initialize_map() {
  var controlPosition = google.maps.ControlPosition.RIGHT_CENTER;
  var coords_dynamic = jQuery("#map").attr('data-coords');
  var splitedcoords = coords_dynamic.split(',');

  var myLatlng = new google.maps.LatLng(splitedcoords[0], splitedcoords[1]);

  var isDraggable = true;
  var mapOptions = {
    zoom: 17,
    gestureHandling: 'cooperative',
    center: myLatlng,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
     styles:mapStyle,
    scrollwheel: false,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    styles:mapStyle
  };


  var map = new google.maps.Map(document.getElementById('map'),
    mapOptions);
//   var iconsource = 'https://www.monastiksuites.com/wp-content/themes/monastik/assets/images/marker.svg';/*map pointer here*/
//   var icons = {
//     markericon: {
//       icon: iconsource
//     }
//   };

  var marker = new google.maps.Marker({
    position: myLatlng,
//     icon: {
// url: "https://www.monastiksuites.com/wp-content/themes/monastik/assets/images/marker.svg"
// },
    map: map
  });

  marker.setAnimation(google.maps.Animation.BOUNCE);
  setTimeout(function(){ marker.setAnimation(null); }, 1000);

  //Map style is not mandatory
//   var styledMap = new google.maps.StyledMapType(mapStyle, {
//     name: "Styled Map"
//   });
//   map.mapTypes.set('mapStyle', styledMap);
//   map.setMapTypeId('mapStyle')

}


/*Map Stores*/

if ($("#map_sights").length > 0) {
  google.maps.event.addDomListener(window, 'load', initialize_map_sights());
}


function initialize_map_sights() {

  var controlPosition = google.maps.ControlPosition.RIGHT_CENTER;
  var coords_dynamic = jQuery("#map_sights").attr('data-coords');
  var splitedcoords = coords_dynamic.split(',');

  var myLatlng = new google.maps.LatLng(splitedcoords[0], splitedcoords[1]);

  var isDraggable = true;
  var mapOptions = {
    zoom: 15,
    gestureHandling: 'cooperative',
    center: myLatlng,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
     styles:mapStyle,
    scrollwheel: false,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    styles:mapStyle
  };


  var map = new google.maps.Map(document.getElementById('map_sights'),
    mapOptions);
//   var iconsource = 'https://www.monastiksuites.com/wp-content/themes/monastik/assets/images/marker.svg';/*map pointer here*/
//   var icons = {
//     markericon: {
//       icon: iconsource
//     }
//   };

  var marker = new google.maps.Marker({
    position: myLatlng,
    // icon: {
    //   url: "https://www.monastiksuites.com/wp-content/themes/monastik/assets/images/marker.svg"
    // },
    map: map
  });

  var infowindow = new google.maps.InfoWindow();
  var marker, i;
  var bounds = new google.maps.LatLngBounds();
        for (i = 0; i < json.length; i++) {
            var coords_dynamic_stores=json[i]['Coords'];
            var splitedcoords_stores = coords_dynamic_stores.split(',');
            marker = new google.maps.Marker({
              position: new google.maps.LatLng(splitedcoords_stores[0], splitedcoords_stores[1]),
              map: map,
            //   icon: {
            //     url: "https://www.monastiksuites.com/wp-content/themes/monastik/assets/images/marker.svg"
            // },
            });

            bounds.extend(marker.position);

            google.maps.event.addListener(marker, 'click', (function(marker, i) {
              return function() {
                infowindow.setContent('<img src="'+json[i]['Image']+'" alt="Sights" /> '+'<p>'+json[i]['Sight']+'</p>'+'<a href="'+json[i]['Directions']+'" class="link" target="_blank"> Directions </a>');
                infowindow.open(map, marker);
              }
            })(marker, i));

        }


        map.fitBounds(bounds);


  //Map style is not mandatory
//   var styledMap = new google.maps.StyledMapType(mapStyle, {
//     name: "Styled Map"
//   });
//   map.mapTypes.set('mapStyle', styledMap);
//   map.setMapTypeId('mapStyle')

}
